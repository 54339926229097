import React, {createContext, useContext, useState} from 'react';

const PageContext = createContext();

const defaltPageData={
    __typename: 'BlankPage'
}

export function PageProvider({children}) {
    const [pageData, setPageData] = useState(defaltPageData);
    const apiFunctions = {
        setPageData,
        pageData
    }



    return (
        <PageContext.Provider value={apiFunctions}>
            {children}
        </PageContext.Provider>
    );
}

export function usePageContext() {
    return useContext(PageContext);
};