import PropTypes from 'prop-types';
import React from 'react';
import ROUTE_NAVIGATION from './routeQuery';
import loadable from "../components/loadable";
import {Query} from "react-apollo";
import {T} from "@deity-io/falcon-i18n";

const PageRenderer = loadable(() => import('../components/PageRenderer/PageRenderer'));
const Product = loadable(() => import('../screens/Product/ProductRenderer'));
const Category = loadable(() => import('../screens/Category/Category'));

const sanitizePath = (path) => {
    // Remove beginning slash
    if (path.startsWith('//')) {
        path = path.substring(2);
    }

    if (path.startsWith('/')) {
        path = path.substring(1);
    }

    // Remove end slash
    if (path.endsWith('/')) {
        path = path.slice(0, -1);
    }
    if (path == '') {
        path = '/';
    }
    return path;
}

const DynamicRouteResolver = ({components, location, loaderComponent, errorComponent, ...props}) => {
    const {pathname} = location;
    const path = sanitizePath(pathname);
    return (
        <Query
            query={ROUTE_NAVIGATION}
            ssr={true}
            variables={
                {
                    url: path
                }
            }
            fetchPolicy="cache-first"
            loaderComponent={loaderComponent}
            errorComponent={errorComponent}
        >
            {(data) => {
                if (data && data.data && data.data.route) {
                    const pageData = data.data.route
                    switch (pageData.__typename) {
                        case 'CmsPage':
                            return (<PageRenderer routeData={pageData}/>)
                        case 'SimpleProduct':
                        case 'GroupedProduct':
                            return (<Product routeData={pageData}/>)
                        case 'CategoryTree':
                            if (!pageData.uid) {
                                return;
                            }
                            return (
                                <div>
                                    <Category
                                        uid={pageData.uid}
                                        pageData={pageData}
                                    />
                                </div>
                            )
                    }
                }

                return (
                    <h1>
                        <T id="category.noServerResponse"/>
                    </h1>
                );

            }
            }
        </Query>
    )

};

DynamicRouteResolver.propTypes = {
    components: PropTypes.shape({}),
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default DynamicRouteResolver;
