import React from 'react';
import {Loader} from '@deity-io/falcon-ecommerce-uikit';
import DynamicRouteResolver from './DynamicRouteResolver';
import {PageProvider} from '../context/PageContext';

export default props => {
    return (
        <PageProvider>

            <DynamicRouteResolver
                {...props}
                loaderComponent={Loader}
            />
        </PageProvider>
    );
};
